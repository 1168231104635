import Vue from 'vue'
import './app.css'
import 'lazysizes'

Vue.config.productionTip = false
Vue.prototype.window = window

Vue.component('Ticker', () => import('./components/Ticker'))
Vue.component('MenuButton', () => import('./components/MenuButton'))
Vue.component('JobsFilter', () => import('./components/home/JobsFilter'))
Vue.component('JobsList', () => import('./components/home/JobsList'))
Vue.component('JobForm', () => import('./components/job-form/JobForm'))
Vue.component('PrivacyPopup', () => import('./components/PrivacyPopup'))
Vue.component('MainMenu', () => import('./components/MainMenu'))
Vue.component('NewsletterPopup', () => import('./components/NewsletterPopup'))

window.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#wwu-root',
    delimiters: ["${", "}"],
  })
})
